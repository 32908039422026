import { z } from 'zod';

export type Profile = {
  email: string;
  email_verified: boolean;
  name: string;
  notification_message: string;
  time_zone: string | null;
  time_zone_minutes: number;
  xauth_method_id: number;
  browser_extension_env: string;
  my_env: string;
  locale: string;
};

export type GroupScope = 'all' | 'part' | 'none';

export type Roles = 'owner' | 'manager' | 'member';

export type Permissions = {
  file_transfer: boolean;
  off_session_file: boolean;
  off_session_chat: boolean;
  chat_transcript: boolean;
  command_prompt: boolean;
  premier_pack: boolean;
  one_to_many: boolean;
  vulnerability_score: boolean;
  sos_service_desk: boolean;
};

export type TeamMemberPermissions = {
  member_list: boolean;
  access_schedule: boolean;
  attended_access: boolean;
  super_admin?: boolean;
  technician_manager: boolean;
  team_settings_management?: boolean;
  user_management?: boolean;
} & Omit<Permissions, 'chat_transcript'>;

export type TeamPermissions = {
  file: boolean;
  premier_pack: boolean;
  member_list: boolean;
  sos_service_desk: boolean;
  bitdefender?: boolean;
  bitdefender2?: boolean;
};

export type Team = {
  team_id: number;
  team_name: string;
  plan: string;
  seat_kind: string;
  role: Roles;
  group_scope: GroupScope;
  is_resell: boolean;
  contact_sales: boolean;
  /**
   * Showing sales email by different region (Global, EU, CN)
   */
  sales_mail?: string;
  member_id: number;
  member_status: 'enabled' | 'disabled' | 'invited' | 'declined' | 'quit' | 'deleted';
  expires_at: string | null;
  team_member_permissions: TeamMemberPermissions;
  team_permissions: TeamPermissions;
  seat_permissions: Permissions;
  seat_settings: {
    per_seat_count: number | null;
  };
};

/**
 * NOTE: Available for production after the feature toggle `PCP_506__TeamSettings_RoleManagement` is released
 */
export type NewTeamKind = 'splashtop' | 'ste_custom';
/**
 * @deprecated Use `TeamKind` instead
 */
export type TeamKeys = 'stp' | 'sba' | 'msp' | 'srs' | 'sos' | NewTeamKind;

/** @deprecated The teams definition from the basic profile API, use the info-query API instead */
export type Teams = Partial<Record<TeamKeys, Team>>;

export type ResponseData = {
  result: number;
  commands: Record<string, string>;
  messages: Array<string>;
};

export type ErrorResponse = {
  statusCode: number;
  messages: Array<string>;
  errorReason: ResponseErrorReason['data'];
};

export type ResponseErrorReason = {
  data: {
    [key: string]: string;
    error: string;
  };
};

export type AccessTokenResponseData = {
  data: {
    access_token: string;
    access_token_ttl: number;
  };
};

export type LatestStreamerVersion = {
  win: string;
  mac: string;
};

export type SystemInfo = {
  latest_streamer_version: LatestStreamerVersion;
};

/** @deprecated use the info-query API data instead */
export type UserBasicProfileData = {
  data: {
    profile: Profile;
    teams: Teams;
    system_info: SystemInfo;
  };
};

export type AlertLogsUnread = {
  id: number;
  alert_code: string;
  group_name: string | null;
  server_name: string;
  alert_display_name: string;
  alert_name: string | null;
  desc_display: string;
  created_at: string;
  ack_status: number;
  ack_time: string | null;
  ack_user: string | null;
  ok_status: number;
  ok_time: string | null;
};

export type AlertLogsUnreadData = {
  data: Array<AlertLogsUnread> | [];
};

export type SsoMethods = {
  id: number;
  name: string;
  enabled: boolean;
  is_default: boolean;
};

export type SsoMethodsData = {
  data: Array<SsoMethods> | [];
};

export type TeamMember =
  | {
      id: number;
      email: string;
      role: 'owner';
      xauth_method_id: number;
      member_name: string;
      status: 'enabled' | 'disabled';
      invite_code: string;
      enable_2fa?: boolean;
    }
  | {
      id: number;
      email: string;
      role: 'manager' | 'member';
      xauth_method_id: number;
      member_name: string;
      status: 'enabled' | 'disabled' | 'invited' | 'declined' | 'quit' | 'deleted';
      invite_code: string;
      technician_manager?: boolean;
      technician_manager_capability?: {
        team_settings_management?: boolean;
        user_management?: boolean;
      };
      enable_2fa?: boolean;
    };

export type TeamMembersData = {
  data: Array<TeamMember>;
};

export type TeamMemberGroup = {
  id: number;
  name: string;
  members_count: number;
};

export type TeamMemberGroupData = {
  data: Array<TeamMemberGroup>;
};

/**
 * TODO: merge with TeamDetailResponse ?
 */
export type SeatInformationResponse = {
  /** example: "2022-12-07T03:28:21Z" */
  created_at: string;
  multiple_magnification: number;
  seats_users_count: number;
  occupied_seat_count: number;
  team_members_count: number;
  on_shelf: boolean;
  can_change_plan: boolean;
  can_trial: boolean;
  seat_is_recurring: boolean;
  seat_auto_renew: boolean;
  can_buy_product: boolean;
  buy_url: string;
  trial_url: string;
  upgrade_url: string | null;
  team_member_permissions: {
    sso: boolean;
  };
  team_permissions: {
    sso: boolean;
  };
  product_options: {
    can_buy_pcs: boolean;
  };
  team_settings: {
    schedule_time_zone_minutes: number;
    schedule_time_zone: string | null;
  };
  team_code: string;
};

export type InviteResponseData = {
  data: {
    id: number;
    email: string;
    sent_at: string;
    status: string;
  };
};

export type InviteOptions = {
  email: string;
  message: string;
  xauthMethodId?: number;
};

export type ReInviteOptions = {
  message: string;
  xauthMethodId?: number;
  cc: Array<string> | null;
};

export type Session = {
  id: number;
  status: 'disconnected' | 'being_disconnected' | 'active';
  token: string;
  created_at: string;
  computer_name: string;
  supporter_name: string;
  supporter_email: string;
  duration: number;
  connect_type: 'local' | 'remote' | 'rdp';
  can_force_disconnect: boolean;
  note: string | null;
};

export type SessionsData = {
  data: Array<Session>;
};

export type ClientConnectUrlData = {
  data: { url: string };
};

export type ClientConnectData = {
  data: {
    status: 'wait' | 'error' | 'connected';
    cl_download_link: string;
    splashtop_business_portable_link: string;
    error: 'version' | 'without_any_client' | null;
  };
};

export type InviterInformation = {
  invitor: {
    email: string;
    name: string;
  };
  has_spid_streamer: boolean;
};

export type CreateAccessScheduleData = {
  start_date: string; //<date YYYY-mm-dd>,
  end_date: string; // <date YYYY-mm-dd>
  name: string;
  description: string; // #預期有換行,
  force_disconn: boolean;
  warn_time_left: number; // #幾分鐘前開始倒數
};

export type TeamMembersCount = {
  total: number;
  occupied: number;
  effective: number;
};

export type PublicInformationData = {
  data: {
    prices: {
      sos: number;
    };
  };
};

export type UserComputersWithCustomizedFields = {
  id: number;
  name: string;
  email: string | null;
  group_id: number | null;
  last_online: string | null;
  pubip: string | null;
  local_ip: string | null;
  version: string | null;
  last_session: string | null;
  online_status: boolean | null;
  is_device_owner: boolean;
  deployed: boolean;
  note: string | null;
};

export type NewVersionData = {
  version: string | null;
};

export type QuickSetupInfoData = {
  srs_download_link: string;
  srs_download_kind: string;
  instruction: string;
  src_launch_uri: string;
};

const unifiedTeams = ['splashtop', 'ste_custom'] as const;
export const unifiedTeamsSchema = z.enum(unifiedTeams);
export type UnifiedTeams = z.infer<typeof unifiedTeamsSchema>;

export const teamKindSchema = z.enum(['msp', 'sos', 'srs', 'sba', 'stp', ...unifiedTeams]);
export type TeamKind = z.infer<typeof teamKindSchema>;
