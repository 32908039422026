import axios from 'axios';

import { featureControl } from '@/feature/toggle';
import { INFO_QUERY, USER_COMPUTERS } from '@/services/common';
import type { ResponseData, ResponseErrorReason, Teams } from '@/services/common/types';
import { ajaxInstance, ajaxPath, checkResponse, isSuccess } from '@/services/utils';

import { computerListTokenListSchema } from './schemas';
import {
  ComputerData,
  ComputerInfo,
  ComputerListCustomizedField,
  ComputerListMode,
  FilterComputersOptions,
  GetComputerListByIdBody,
  UserComputer,
} from './types';
import { ComputerDataList } from './types';

export * from './getTeamComputerDetail_deprecated';
export * from './getUserComputerDetail_deprecated';
export * from './getStreamerVersion';

export * from './services';
export * from './types';
export * from './schemas';

function getComputerCount(teamId: number) {
  return new Promise<number>((resolve, reject) => {
    axios
      .get<ResponseData & { data: number }>(`/api/web/v1/teams/${teamId}/computers/total_count`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getComputerIds(teamId: number) {
  return new Promise<Array<number>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<number> }>(`/api/web/v1/teams/${teamId}/computers/ids`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getComputerList(teamId: number) {
  return new Promise<Array<ComputerInfo>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<ComputerInfo> }>(`/api/web/v1/teams/${teamId}/computers`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getComputerListById(
  teamId: number,
  computerIds: Array<number>,
  mode: ComputerListMode = 'customize',
  customizedFields: Array<ComputerListCustomizedField> = ['group_id', 'online_status', 'version', 'vulnerability_status'],
  options?: FilterComputersOptions,
) {
  const body: GetComputerListByIdBody = {
    ids: computerIds,
    mode,
    customized_fields: customizedFields,
  };

  if (options?.feature) {
    body.features = [options.feature];
  }

  if (options?.actionKind) {
    body.action_kind = options.actionKind;
  }
  if (options?.onlineStatus) {
    body.online_status = options.onlineStatus;
  }
  if (options?.actionType) {
    body.action_type = options.actionType;
  }
  if (options?.capability) {
    body.capability = options.capability;
  }

  return new Promise<ComputerDataList>((resolve, reject) => {
    axios
      .post<ResponseData & { data: ComputerDataList }>(`/api/web/v1/teams/${teamId}/computers/query_by_numerous_ids`, body)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getComputerListByToken(
  teamId: number,
  {
    token,
    mode = 'customize',
    customizedFields = ['group_id', 'online_status', 'version', 'vulnerability_status'],
    options,
  }: {
    token: string;
    mode: ComputerListMode;
    customizedFields: Array<ComputerListCustomizedField>;
    options?: FilterComputersOptions;
  },
) {
  const body: Omit<GetComputerListByIdBody, 'ids'> = {
    token,
    mode,
    customized_fields: customizedFields,
  };

  if (options?.feature) {
    body.features = [options.feature];
  }

  if (options?.actionKind) {
    body.action_kind = options.actionKind;
  }
  if (options?.onlineStatus) {
    body.online_status = options.onlineStatus;
  }
  if (options?.actionType) {
    body.action_type = options.actionType;
  }
  if (options?.capability) {
    body.capability = options.capability;
  }

  return new Promise<ComputerDataList>((resolve, reject) => {
    axios
      .post<ResponseData & { data: ComputerDataList }>(`/api/web/v1/teams/${teamId}/computers/query_by_numerous_ids`, body)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getComputerLisWithCapacityById(
  teamId: number,
  computerIds: Array<number>,
  mode: ComputerListMode = 'customize',
  customizedFields: Array<ComputerListCustomizedField> = ['group_id', 'online_status', 'version', 'vulnerability_status'],
  capacity?: string,
) {
  const body: GetComputerListByIdBody = {
    ids: computerIds,
    mode,
    customized_fields: customizedFields,
  };

  if (capacity) {
    body.features = ['capability'];
    body.capability = capacity;
  }
  return new Promise<ComputerDataList>((resolve, reject) => {
    axios
      .post<ResponseData & { data: ComputerDataList }>(`/api/web/v1/teams/${teamId}/computers/query_by_numerous_ids/`, body)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type ComputerGroupData = {
  id: number | null;
  name: string;
  selected?: boolean;
  computers_count?: number;
  in_charged?: boolean;
};
export type ComputerGroupDataList = Array<ComputerGroupData>;

export type GetComputerListData = {
  computers: ComputerDataList;
  groups: ComputerGroupDataList;
};

function getComputerListInAccessSchedule(teamId: number, accessScheduleId: number) {
  return new Promise<GetComputerListData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetComputerListData }>(`/api/web/v1/teams/${teamId}/access_schedules/${accessScheduleId}/computers`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type GetComputerIdListData = {
  computers: Array<number>;
  groups: Array<number>;
};

function getComputerListIdInAccessSchedule(teamId: number, accessScheduleId: number) {
  return new Promise<GetComputerIdListData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetComputerIdListData }>(
        `/api/web/v1/teams/${teamId}/access_schedules/${accessScheduleId}/computers?mode=simple`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type UpdateResourceComputerListParams = {
  computers: { add: Array<number>; remove: Array<number> };
  groups: { add: Array<number>; remove: Array<number> };
};

type UpdateResourceComputerListData = {
  computers: {
    success: Array<number>;
    fail: Array<number>;
  };
  groups: {
    success: Array<number>;
    fail: Array<number>;
  };
};

function updateComputerListInAccessSchedule(teamId: number, accessScheduleId: number, requestBody: UpdateResourceComputerListParams) {
  return new Promise<UpdateResourceComputerListData>((resolve, reject) => {
    axios
      .patch<ResponseData & { data: UpdateResourceComputerListData }>(
        `/api/web/v1/teams/${teamId}/access_schedules/${accessScheduleId}/computers/assign`,
        requestBody,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getComputerListInTeamMemberAccessPermissions(teamId: number, teamMemberId: string) {
  return new Promise<GetComputerListData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetComputerListData }>(
        `/api/web/v1/teams/${teamId}/access_permission/team_members/${teamMemberId}/computers`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getComputerIdListInTeamMemberAccessPermissions(teamId: number, teamMemberId: string) {
  return new Promise<GetComputerIdListData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetComputerIdListData }>(
        `/api/web/v1/teams/${teamId}/access_permission/team_members/${teamMemberId}/computers?mode=simple`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getComputerListInTeamGroupMemberAccessPermissions(teamId: number, groupId: string) {
  return new Promise<GetComputerListData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetComputerListData }>(`/api/web/v1/teams/${teamId}/access_permission/groups/${groupId}/computers`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getComputerIdListInTeamGroupMemberAccessPermissions(teamId: number, groupId: string) {
  return new Promise<GetComputerIdListData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetComputerIdListData }>(
        `/api/web/v1/teams/${teamId}/access_permission/groups/${groupId}/computers?mode=simple`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getComputerListInOneToManySchedules(teamId: number, scheduleId: string) {
  return new Promise<GetComputerListData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetComputerListData }>(`/api/web/v1/teams/${teamId}/one_to_many/schedules/${scheduleId}/computers`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getComputerIdListInOneToManySchedules(teamId: number, scheduleId: string) {
  return new Promise<GetComputerIdListData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetComputerIdListData }>(
        `/api/web/v1/teams/${teamId}/one_to_many/schedules/${scheduleId}/computers?mode=simple`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type GetComputerIdsInGroupData = Array<number>;

function getComputerIdsInGroup(teamId: number, groupId: string) {
  return new Promise<GetComputerIdsInGroupData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetComputerIdsInGroupData }>(`/api/web/v1/teams/${teamId}/groups/${groupId}/computers`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getComputersInAlertProfile(teamId: number, alertProfileId: string) {
  return new Promise<GetComputerListData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetComputerListData }>(`/api/web/v1/teams/${teamId}/alert_profiles/${alertProfileId}/computers`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getComputerIdsInAlertProfile(teamId: number, alertProfileId: string) {
  return new Promise<GetComputerIdListData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetComputerIdListData }>(
        `/api/web/v1/teams/${teamId}/alert_profiles/${alertProfileId}/computers?mode=simple`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function updateComputerListInAlertProfile(teamId: number, alertProfileId: string, requestBody: UpdateResourceComputerListParams) {
  return new Promise<UpdateResourceComputerListData>((resolve, reject) => {
    axios
      .patch<ResponseData & { data: UpdateResourceComputerListData }>(
        `/api/web/v1/teams/${teamId}/alert_profiles/${alertProfileId}/computers/assign`,
        requestBody,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getUserComputerIdList() {
  return new Promise<Array<number>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<number> }>(`${USER_COMPUTERS}/ids`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

/**
 * @deprecated Use `getUserComputerListByIds` from `@/services/computers/services/getUserComputerListById` instead.
 */
function getUserComputerListByIds({ idList, fields }: { idList: Array<number>; fields: Array<ComputerListCustomizedField> }) {
  return new Promise<Array<UserComputer>>((resolve, reject) => {
    axios
      .post<ResponseData & { data: Array<UserComputer> }>(`${USER_COMPUTERS}/query_by_numerous_ids`, {
        customized_fields: fields.join(','),
        ids: idList,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getUserComputerListByToken({ token, fields }: { token: string; fields: Array<ComputerListCustomizedField> }) {
  return new Promise<Array<UserComputer>>((resolve, reject) => {
    axios
      .post<ResponseData & { data: Array<UserComputer> }>(`${USER_COMPUTERS}/query_by_numerous_ids`, {
        customized_fields: fields.join(','),
        token,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getUserComputerList({ fields }: { fields: Array<ComputerListCustomizedField> }) {
  return new Promise<Array<UserComputer>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<UserComputer> }>(`${USER_COMPUTERS}`, {
        params: {
          customized_fields: fields.join(','),
        },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getUserComputerUUID({ computerId }: { computerId: number }) {
  return new Promise<NonNullable<UserComputer['uuid']> | null>((resolve, reject) => {
    axios
      .post<ResponseData & { data: Array<Pick<UserComputer, 'uuid'>> }>(`${USER_COMPUTERS}/query_by_numerous_ids`, {
        mode: 'customize',
        customized_fields: 'uuid',
        ids: [computerId],
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data[0].uuid ?? null);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getTeamComputerUUID({ teamId, computerId }: { teamId: number; computerId: number }) {
  return new Promise<NonNullable<ComputerData['uuid']> | null>((resolve, reject) => {
    axios
      .post<ResponseData & { data: Array<Pick<ComputerData, 'uuid'>> }>(`/api/web/v1/teams/${teamId}/computers/query_by_numerous_ids`, {
        mode: 'customize',
        customized_fields: 'uuid',
        ids: [computerId],
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data[0].uuid ?? null);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

type GetBusinessAppURIProps = {
  computerId: number;
  isAndroid?: boolean;
  /** NOTE: Only Mac and Windows are supported for SSH session */
  isSSH?: boolean;
};
function getBusinessAppURI({ computerId, isAndroid, isSSH }: GetBusinessAppURIProps) {
  return new Promise<{ url: string }>((resolve, reject) => {
    const payload = {
      type: 'get_connect_to_url',
      id: computerId,
      kind: isSSH ? 'ssh' : 'connect',
      ...(isAndroid ? { browser_platform: 'android' } : {}),
    };

    ajaxInstance
      .post<{ success: boolean; url: string }>(ajaxPath, payload)
      .then((res) => {
        const {
          data: { success, url },
        } = res;

        if (success) {
          resolve({ url });
        } else {
          reject({ statusCode: 9487, messages: [], errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getRemoteCommandURI({ computerId, isAndroid }: { computerId: number; isAndroid?: boolean }) {
  return new Promise<{ url: string }>((resolve, reject) => {
    const payload = {
      type: 'get_connect_to_url',
      id: computerId,
      kind: 'command_prompt',
      token: '',
      ...(isAndroid ? { browser_platform: 'android' } : {}),
    };

    ajaxInstance
      .post<{ success: boolean; url: string }>(ajaxPath, payload)
      .then((res) => {
        const {
          data: { success, url },
        } = res;

        if (success) {
          resolve({ url });
        } else {
          reject({ statusCode: 9487, messages: [], errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function updateUserComputer(computerId: number, payload: { name?: string; note?: string; group_id?: number }) {
  return new Promise<void>((resolve, reject) => {
    axios
      .patch<ResponseData & { data: ResponseErrorReason }>(`${USER_COMPUTERS}/${computerId}`, payload)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve();
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type ComputerActionSetting = {
  text: string;
  display: boolean;
  active: boolean;
  tooltip: boolean;
  tooltip_message: string | null;
};
type ComputerActionKey =
  | 'force_disconnect'
  | 'reboot_computer'
  | 'wake_computer'
  | 'delete_computer'
  | 'change_name'
  | 'assign_computer_group'
  | 'add_note'
  | 'check_for_updates'
  | 'antivirus'
  | 'vulnerability_score'
  | 'see_alerts'
  | 'schedules'
  | 'check_for_inventory'
  | 'check_for_event_logs'
  | 'check_for_command_prompt'
  | 'task_manager'
  | 'service_manager'
  | 'registry_editor'
  | 'device_manager'
  | 'msp_access_permission'
  | 'access_permission'
  | 'feature_permission'
  | 'properties';
export type ComputerActionSettingMap = Partial<Record<ComputerActionKey, ComputerActionSetting>>;
function getUserComputerActionSettingMap(computerId: number) {
  return new Promise<ComputerActionSettingMap>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ComputerActionSettingMap }>(`/api/web/v1/ui/computer_list/my_computer_gear_option/${computerId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
function getTeamComputerActionSettingMap(computerId: number) {
  return new Promise<ComputerActionSettingMap>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ComputerActionSettingMap }>(`/api/web/v1/ui/computer_list/all_computer_gear_option/${computerId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type BackgroundActionsKind = 'task_manager' | 'service_manager' | 'registry_editor' | 'device_manager';
function checkBackgroundActions(devUUID: string, kind: BackgroundActionsKind) {
  return new Promise<{ session_url: string }>((resolve, reject) => {
    axios
      .post<ResponseData & { data: { session_url: string } }>(
        `/api/web/v1/premium_session`,
        { dev_uuid: devUUID, premium_tool: kind },
        {
          headers: {
            'X-SP-DevUUID': localStorage.getItem('pcp_dev_uuid'),
          },
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function updateComputerNote(computerId: number, note: string) {
  return new Promise<void>((resolve, reject) => {
    axios
      .put<ResponseData & { data: ResponseErrorReason }>(`/api/web/v1/computers/${computerId}/update_note`, { note })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve();
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function updateComputerName(computerId: number, name: string) {
  return new Promise<void>((resolve, reject) => {
    axios
      .put<ResponseData & { data: ResponseErrorReason }>(`/api/web/v1/computers/${computerId}/rename`, { name })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve();
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

/**
 * @deprecated Use `RebootComputerKind` in `./types.ts` instead
 */
export type RebootComputerKind_deprecated = 'restart_streamer' | 'reboot' | 'safe_mode_reboot' | 'shut_down' | 'log_off';
/**
 * @deprecated Use `RebootComputerOptionMap` in `./types.ts` instead
 */
export type RebootComputerOptionMap_deprecated = Record<RebootComputerKind_deprecated, boolean>;

/**
 * @deprecated Use `getRebootComputerOptionMap` in `./types.ts` instead
 */
function getRebootComputerOptionMap_deprecated(computerId: number) {
  return new Promise<RebootComputerOptionMap_deprecated>((resolve, reject) => {
    axios
      .get<ResponseData & { data: RebootComputerOptionMap_deprecated }>(`/api/web/v1/computers/${computerId}/reboot_computer_option`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

/**
 * @deprecated
 */
function wakeComputer_deprecated({
  computerId,
  computerName,
  sendComputerIds,
}: {
  computerId: number;
  computerName: string;
  sendComputerIds: Array<string>;
}) {
  const requestParams = new URLSearchParams();
  requestParams.append('type', 'check_team_online_srs');
  requestParams.append('did', String(computerId));
  requestParams.append('server_name', computerName);
  sendComputerIds.forEach((id) => {
    requestParams.append('server_ids[]', id);
  });

  return new Promise<{ success: boolean }>((resolve, reject) => {
    ajaxInstance
      .post<{ success: boolean; msg?: string }>(ajaxPath, requestParams.toString())
      .then((res) => {
        const {
          data: { success, msg },
        } = res;

        if (success) {
          resolve({ success });
        } else {
          reject({ statusCode: 9487, messages: [], errorReason: { error: msg } });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type ConnectInformation = { src_name: string; src_spid: string };
function getConnectInformation(computerId: number) {
  return new Promise<ConnectInformation>((resolve, reject) => {
    ajaxInstance
      .post<{ success: boolean } & ConnectInformation>(ajaxPath, {
        type: 'get_connect_client',
        sid: computerId,
      })
      .then((res) => {
        const {
          data: { success, src_name, src_spid },
        } = res;

        if (success) {
          resolve({ src_name, src_spid });
        } else {
          reject({ statusCode: 9487, messages: [], errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function forceDisconnect(computerId: number) {
  return new Promise<{ success: boolean }>((resolve, reject) => {
    ajaxInstance
      .post<{ success: boolean }>(`/disconn_srs`, {
        sid: computerId,
      })
      .then((res) => {
        const {
          data: { success },
        } = res;

        if (success) {
          resolve({ success });
        } else {
          reject({ statusCode: 9487, messages: [], errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type ComputerListColumnPermissions = {
  team_permissions: {
    /** Ability to show group to member */
    display_group_to_member: boolean;
    /** Ability to show computer notes */
    member_fetch_computer_notes: boolean;
    /** Ability to show security layers */
    manage_infra_gen: boolean;
    /** Ability to show antivirus status */
    antivirus?: boolean;
    /** Ability to use web client to connect streamer */
    web_src: boolean;
    /** Ability to remote reboot computer */
    remote_reboot: boolean;
    /** Members has ability to remote reboot computer */
    member_reboot_computer: boolean;
    /** Ability to use preference policy */
    preference_policy: boolean;
  };
};
function getComputerListColumnPermissions() {
  return new Promise<ComputerListColumnPermissions>((resolve, reject) => {
    axios
      .post<ResponseData & { data: Record<keyof Teams, ComputerListColumnPermissions> }>(INFO_QUERY, {
        team_permissions: [
          'display_group_to_member',
          'member_fetch_computer_notes',
          'manage_infra_gen',
          'antivirus',
          'web_src',
          'remote_reboot',
          'member_reboot_computer',
          ...(featureControl.getToggle('PCP_1134__Policy_management') ? ['preference_policy'] : []),
        ],
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        const unattendedTeamKind = Object.keys(data).find((teamKind) => teamKind !== 'sos') as keyof Teams | undefined;

        if (isSuccess(result) && unattendedTeamKind) {
          const unattendedPermissions = data[unattendedTeamKind];
          resolve(unattendedPermissions);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

/**
 * - All of the roles can access this API
 *   - Only the member requires `member_list` permission
 * - The token should always along with the `ids` id list
 */
export function getUserComputerTokenList() {
  return checkResponse(axios.get('/api/web/v1/users/computers/tokens'), computerListTokenListSchema);
}

/**
 * - All of the roles can access this API
 *   - Only the member requires `member_list` permission
 * - The token should always along with the `ids` id list
 */
export function getTeamComputerTokenList(teamId: number) {
  return checkResponse(axios.get(`/api/web/v1/teams/${teamId}/computers/tokens`), computerListTokenListSchema);
}

export {
  getComputerCount,
  getComputerIds,
  getComputerList,
  getComputerListById,
  getComputerLisWithCapacityById,
  getComputerListInAccessSchedule,
  getComputerListIdInAccessSchedule,
  updateComputerListInAccessSchedule,
  getComputerListInTeamMemberAccessPermissions,
  getComputerIdListInTeamMemberAccessPermissions,
  getComputerListInOneToManySchedules,
  getComputerIdListInOneToManySchedules,
  getComputerListInTeamGroupMemberAccessPermissions,
  getComputerIdListInTeamGroupMemberAccessPermissions,
  getComputersInAlertProfile,
  getComputerIdsInAlertProfile,
  updateComputerListInAlertProfile,
  getComputerIdsInGroup,
  getUserComputerIdList,
  getUserComputerListByIds,
  getBusinessAppURI,
  getRemoteCommandURI,
  updateUserComputer,
  getUserComputerActionSettingMap,
  getTeamComputerActionSettingMap,
  checkBackgroundActions,
  updateComputerNote,
  updateComputerName,
  getRebootComputerOptionMap_deprecated,
  wakeComputer_deprecated,
  getConnectInformation,
  forceDisconnect,
  getComputerListColumnPermissions,
  getUserComputerUUID,
  getTeamComputerUUID,
};
