import { z } from 'zod';

import { SUCCESS_RESULT } from './constants';

/**
 * Common datetime string from API.
 *
 * @example `2021-01-28 00:00:00`
 */
export const datetimeSchema = z.string().regex(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/);

// zod
export const commonResponseSchema = z.object({
  result: z.number(),
  data: z.unknown(),
  messages: z.array(z.string()),
});

export const successResponseSchema = commonResponseSchema.merge(
  z.object({
    result: z.literal(SUCCESS_RESULT),
  }),
);

/******* Generic API Errors Start *******/
/**
 * Two Step Verification error
 * The general error handling on PCP is redirect user to account info page to enable 2sv:
 *
 * - User is the owner of the team:
 *  `?msg=pref_policy_security_to_owner`
 *
 * - User is *NOT* the owner:
 *  `?msg=pref_policy_security_to_owner_without_granular`
 *
 * @ref src/modules/TeamSettings/SettingPanel/SettingControlContextProvider/SettingControlContextProvider_new.tsx L141
 */
export const twoStepVerificationErrorSchema = commonResponseSchema.merge(
  z.object({ result: z.literal(40401), data: z.object({ error: z.literal('required_2sv') }) }),
);

// 40403
/** The team seat is expired */
export const teamSeatExpiredErrorSchema = commonResponseSchema.merge(
  z.object({ result: z.literal(40403), data: z.object({ error: z.literal('is_expired') }) }),
);
/** User can not access to current group scope  */
export const groupScopeErrorSchema = commonResponseSchema.merge(
  z.object({ result: z.literal(40403), data: z.object({ error: z.literal('group_scope') }) }),
);

// 40404
/** The API resource is not support to teams's feature */
export const notMatchAnyProductErrorSchema = commonResponseSchema.merge(
  z.object({ result: z.literal(40404), data: z.object({ error: z.literal('not_match_any_product') }) }),
);
/** The user does not have a team */
export const userHasNoTeamErrorSchema = commonResponseSchema.merge(
  z.object({ result: z.literal(40404), data: z.object({ error: z.literal('team_id') }) }),
);
/** The requested resource not exist */
export const resourceNotExistErrorSchema = commonResponseSchema.merge(
  z.object({ result: z.literal(40404), data: z.object({ error: z.literal('resource_not_exists') }) }),
);

// 40409
/** Exceeded the limit of batch actions */
export const exceededLimitErrorSchema = commonResponseSchema.merge(
  z.object({ result: z.literal(40409), data: z.object({ error: z.literal('exceed_number_limit') }) }),
);

// 40422
/** The parameter is wrong */
export const wrongParamsErrorSchema = commonResponseSchema.merge(
  z.object({ result: z.literal(40422), data: z.object({ error: z.literal('wrong_params') }) }),
);

// 41403
/** The user's role is not supported */
export const roleNotSupportedErrorSchema = commonResponseSchema.merge(
  z.object({ result: z.literal(41403), data: z.object({ error: z.literal('role') }) }),
);
/** The user is disabled */
export const memberDisabledErrorSchema = commonResponseSchema.merge(
  z.object({ result: z.literal(41403), data: z.object({ error: z.literal('member_status') }) }),
);

/** The user's permission is not satisfy */
export const teamMemberPermissionErrorSchema = commonResponseSchema.merge(
  z.object({ result: z.literal(41403), data: z.object({ error: z.literal('team_member_permissions') }) }),
);

/******* Generic API Errors End *******/
